import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

export const BonusStatDisplay = ({ stats, color }) => {
  if (!stats || stats.length === 0) return null;

  const { name, tripleStats, twentyStats } = stats[0];
  const formattedStats = tripleStats
    ? tripleStats.map((stat, index) => `${Object.values(stat)}${Object.keys(stat)[0].toUpperCase()}`).join('-')
    : twentyStats.map((stat, index) => `${Object.values(stat)}${Object.keys(stat)[0].toUpperCase()}`).join('-');

  return (
    <div>
      <Label circular className="sched-bonus-label" color="orange">
        <Icon name="rocket" className="bonus-icon" />
      </Label>
      {name.split(' ')[1]}
      <div style={{ color }}>
        <b>{formattedStats}</b>
      </div>
    </div>
  );
};
