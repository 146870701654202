import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _, { set } from 'lodash';
import { Header, Icon, Table, Loader, Grid, Radio, Dropdown } from 'semantic-ui-react';
import BonusRows from './table_helpers/BonusRows';
import { formatDollars } from '../utils';
import {
  BONUS_FILTER_OPTIONS,
  BONUS_TEAM_OPTIONS,
  BONUS_TYPE_OPTIONS,
  GAME_BONUS_TYPES
 } from '../constants/bonusPool';
import './styles/perf-bonus.scss';

const icons = {
  'nba': 'basketball ball',
  'mlb': 'baseball ball'
}

const PerfBonus = ({ activeGameWeek, gameEntrants, activePool, alphTeams, bonusEvents: propBonusEvents }) => {
  const initialWeeks = Array.from({ length: activeGameWeek }, (_, i) => i + 1);
  const initialUsers = gameEntrants.map((e) => e.username);
  const initalWeekOptions = Array.from({ length: activeGameWeek }, (_, i) => ({
    key: i + 1,
    text: `Week ${i + 1}`,
    value: i + 1,
  }));

  const [allUsersOn, setAllUsersOn] = useState(true);
  const [allWeeksOn, setAllWeeksOn] = useState(true);
  const [allTeamsOn, setAllTeamsOn] = useState(true);
  const [allTypesOn, setAllTypesOn] = useState(true);

  const [users, setUsers] = useState(initialUsers);
  const [usersSelected, setUsersSelected] = useState([]);
  const [usersTouched, setUsersTouched] = useState(false);

  const [weeks, setWeeks] = useState(initialWeeks);
  const [weeksSelected, setWeeksSelected] = useState([]);
  const [weeksTouched, setWeeksTouched] = useState(false);

  const [allTeams, setAllTeams] = useState(alphTeams || null);
  const [teams, setTeams] = useState(alphTeams || null);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [teamsTouched, setTeamsTouched] = useState(false);

  const [allTypes, setAllTypes] = useState(
    GAME_BONUS_TYPES[activePool.game.toUpperCase()]
  );
  const [types, setTypes] = useState(
    GAME_BONUS_TYPES[activePool.game.toUpperCase()]
  );
  const [typesSelected, setTypesSelected] = useState([]);
  const [typesTouched, setTypesTouched] = useState(false);

  const [allBonusEvents, setAllBonusEvents] = useState(propBonusEvents || null);
  const [bonusEvents, setBonusEvents] = useState(propBonusEvents || null);
  const [bonusProcessed, setBonusProcessed] = useState(false);
  const [bonusTotal, setBonusTotal] = useState(0);

  const [dollars, setDollars] = useState('Prizes');
  const [weekOptions, setWeekOptions] = useState(initalWeekOptions);
  const [userOptions, setUserOptions] = useState(() => {
    const userKeys = gameEntrants.map((entrant, index) => ({
      key: index,
      text: entrant.username,
      value: entrant.username,
    }));
  
    return _.sortBy(userKeys, [(user) => user.text.toString().toLowerCase()]);
  });

  useEffect(() => {
    filterBonusTable()
  }, [users, weeks, types, teams, dollars])

  const filterBonusTable = () => {
    let bonuses = allBonusEvents.filter(bonus => {
      return (
        users.includes(bonus.username) &&
        weeks.includes(bonus.week) &&
        types.includes(bonus.bonus_type) &&
        teams.includes(bonus.team_abb)
      )
    });

    if (dollars === 'Prizes') {
      bonuses = bonuses.filter(bonus => bonus.dollars_total > 0)
    };
    if (dollars === 'Payouts') {
      bonuses = bonuses.filter(bonus => bonus.dollars_total < 0)
    }
    // let sum = 0;
    let total = bonuses.reduce((sum, bonus) => {return sum + bonus.dollars_total}, 0);

    setBonusEvents(bonuses);
    setBonusTotal(total);
    setBonusProcessed(true);
  }

  const toggleAllUsers = async () => {
    if (allUsersOn) {
      console.log('all users on');
      setAllUsersOn(false);

      if (usersTouched) {
        console.log('users not touched')
        setUsers(usersSelected);
      } else {
        console.log('users touched')
        setUsers([]);
        setUsersTouched(true);
      }
    } else {
      console.log('all users off');
      setAllUsersOn(true);
      setUsers(initialUsers);
    }
  }

  const toggleAllWeeks = async () => {
    if (allWeeksOn) {
      setAllWeeksOn(false);

      if (weeksTouched) {
        setWeeks(weeksSelected);
      } else {
        setWeeks([]);
        setWeeksTouched(true);
      }
    } else {
      setAllWeeksOn(true);
      setWeeks(initialWeeks);
    }
  }

  const toggleAllTypes = async () => {
    if (allTypesOn) {
      setAllTypesOn(false);

      if (typesTouched) {
        setTypes(typesSelected);
      } else {
        setTypes([]);
        setTypesTouched(true);
      }
    } else {
      setAllTypesOn(true);
      setTypes(allTypes);
    }
  }

  const toggleAllTeams = async () => {
    if (allTeamsOn) {
      setAllTeamsOn(false);

      if (teamsTouched) {
        setTeams(teamsSelected);
      } else {
        setTeams([]);
        setTeamsTouched(true);
      }
    } else {
      setAllTeamsOn(true);
      setTeams(allTeams);
    }
  }

  const changeUsers = async (e, { value }) => {
    console.log('value is ', value);
    setUsersSelected(value);
    setUsers(value);
  };

  const changeWeeks = async (e, { value }) => {
    setWeeksSelected(value);
    setWeeks(value);
  };

  const changeTypes = async (e, { value }) => {
    setTypesSelected(value);
    setTypes(value);
  };

  const changeTeams = async (e, { value }) => {
    setTeamsSelected(value);
    setTeams(value);
  };

  const changeDollars = async (e, { value }) => {
    setDollars(value);
  };

  if (bonusEvents !== null && bonusProcessed && weekOptions.length === activeGameWeek && userOptions.length === gameEntrants.length && alphTeams.length > 0) {
    return (
      <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className={`page-header ${activePool.game} bonuses`}>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{display: 'inline-block'}}>
                  <Icon name='rocket' circular inverted size='large'/>
                </div>
                <Header.Content>
                  Performance Bonuses
                  <Header.Subheader>
                    - Use the filters to view bonuses by Entrant, Week, Bonus Type, and/or {activePool.game.toUpperCase()} team -- or just browse the full list. <br />
                    - By default, Prizes are shown. To also view Payouts (penalties), use the <b>View</b> filter.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={5} divided>
          <Grid.Column>
            <Radio toggle
              className="nba-bonus-toggle"
              checked={allUsersOn}
              label='Show All Users'
              onClick={() => toggleAllUsers()}
            />
            {allUsersOn ? null :
              <Dropdown placeholder='Select Users' fluid multiple selection options={userOptions}
                className="perf-bonus-sel"
                value={usersTouched ? usersSelected : users}
                onChange={changeUsers}
              />
            }
          </Grid.Column>
          <Grid.Column>
            <Radio toggle
              className="nba-bonus-toggle"
              checked={allWeeksOn}
              label='Show All Weeks'
              onClick={() => toggleAllWeeks()}
            />
            {allWeeksOn ? null :
              <Dropdown placeholder='Select Weeks' fluid multiple selection options={weekOptions}
                className="perf-bonus-sel"
                value={weeksTouched ? weeksSelected : weeks}
                onChange={changeWeeks}
              />
            }
          </Grid.Column>
          <Grid.Column>
            <Radio toggle
              className="nba-bonus-toggle"
              checked={allTypesOn}
              label='Show All Types'
              onClick={toggleAllTypes}
            />
            {allTypesOn ? null :
              <Dropdown placeholder='Select Types' fluid multiple selection options={BONUS_TYPE_OPTIONS[activePool.game.toUpperCase()]}
                className="perf-bonus-sel"
                value={typesTouched ? typesSelected : types}
                onChange={changeTypes}
              />
            }
          </Grid.Column>
          <Grid.Column>
            <Radio toggle
              className="nba-bonus-toggle"
              checked={allTeamsOn}
              label='Show All Teams'
              onClick={() => toggleAllTeams()}
            />
            {allTeamsOn ? null :
              <Dropdown placeholder='Select Teams' fluid multiple selection options={BONUS_TEAM_OPTIONS[activePool.game.toUpperCase()]}
                className="perf-bonus-sel"
                value={teamsTouched ? teamsSelected : teams}
                onChange={changeTeams}
              />
            }
          </Grid.Column>
          <Grid.Column>
            <Dropdown fluid selection options={BONUS_FILTER_OPTIONS}
              text={`View: ${dollars.toUpperCase()}`}
              value={dollars}
              onChange={changeDollars}
              icon={icons[activePool.game]}
              floating
              labeled
              button
              className='icon view-filter'
            />
          </Grid.Column>
        </Grid>
        <Table compact className="perf-bonus">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Entry</Table.HeaderCell>
              <Table.HeaderCell>Week</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Game</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Team</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
              {
                dollars !== 'Prizes' ? <Table.HeaderCell>Winners</Table.HeaderCell> : null
              }
              {usersSelected.length === 1 && !allUsersOn ?
                <Table.HeaderCell style={{paddingTop: 5, paddingBottom: 0, fontSize: 18, fontWeight: 600}}>
                  <span style={bonusTotal<0 ? {color: '#CF2E2E'} : null}>
                    {formatDollars(bonusTotal, 2)}
                  </span>
                  <span style={{fontSize: 8, paddingLeft: 5}}>TOTAL</span>
                </Table.HeaderCell>
                :
                <Table.HeaderCell>$</Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <BonusRows bonusEvents={bonusEvents} dollars={dollars} game={activePool.game}/>
          </Table.Body>
        </Table>
        {!bonusEvents.length ?
          <div className="picks-hidden">
            <Icon name='rocket' size='massive' />
            {!allUsersOn ||
            !allWeeksOn ||
            !allTeamsOn ||
            !allTypesOn ?
              <div className="picks-hidden">Use the filters and dropdowns to view this pool's Performance Bonuses.</div>
            :
              <div className="picks-hidden">Bonuses will show up here once they have been earned.</div>
            }
          </div>
        : null}
      </div>
    )
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
  }
}

function mapStateToProps ({ bonusEvents, alphTeams, activeGameWeek, gameEntrants, activePool }) {
  return ({ bonusEvents, alphTeams, activeGameWeek, gameEntrants, activePool })
}

export default connect(mapStateToProps)(PerfBonus);
