export const GAME_BONUS_TYPES = {
  NBA: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  MLB: [5, 10, 11, 12, 13, 14, 15]
}

export const BONUS_TYPE_OPTIONS = {
  NBA: [
    { key: '50pts', text: '50 PTS', value: 1 },
    { key: '20-20', text: '20-20', value: 2 },
    { key: '140tmPts', text: '140 TM PTS', value: 3 },
    { key: '20run', text: '20-0 RUN', value: 4 },
    { key: 'perfWeek', text: 'PERFECT WEEK', value: 5 },
    { key: '30ptWin', text: '30+ PT WIN', value: 6 },
    { key: '2otWin', text: '2-OT+ WIN', value: 7 },
    { key: 'buzzer', text: 'BUZZER-BEATER', value: 8 },
    { key: 'tripDub', text: 'TRIPLE-DOUBLE', value: 9 }
  ],
  MLB: [
    { key: 'perfGm', text: 'PERFECT GAME', value: 10 },
    { key: 'nono', text: 'NO HITTER', value: 11},
    { key: 'perfWk', text: 'PERFECT WEEK', value: 5},
    { key: 'cycle', text: 'CYCLE', value: 12},
    { key: 'grandSlam', text: 'GRAND SLAM', value: 13},
    { key: '15runs', text: '15+ RUNS', value: 14},
    { key: 'astroBean', text: 'ASTRO BEANED', value: 15}
  ]
}

export const BONUS_TEAM_OPTIONS = {
  NBA: [
    { key: 'ATL', text: 'ATL', value: 'ATL' },
    { key: 'BKN', text: 'BKN', value: 'BKN' },
    { key: 'BOS', text: 'BOS', value: 'BOS' },
    { key: 'CHA', text: 'CHA', value: 'CHA' },
    { key: 'CHI', text: 'CHI', value: 'CHI' },
    { key: 'CLE', text: 'CLE', value: 'CLE' },
    { key: 'DAL', text: 'DAL', value: 'DAL' },
    { key: 'DEN', text: 'DEN', value: 'DEN' },
    { key: 'DET', text: 'DET', value: 'DET' },
    { key: 'GSW', text: 'GSW', value: 'GSW' },
    { key: 'HOU', text: 'HOU', value: 'HOU' },
    { key: 'IND', text: 'IND', value: 'IND' },
    { key: 'LAC', text: 'LAC', value: 'LAC' },
    { key: 'LAL', text: 'LAL', value: 'LAL' },
    { key: 'MEM', text: 'MEM', value: 'MEM' },
    { key: 'MIA', text: 'MIA', value: 'MIA' },
    { key: 'MIL', text: 'MIL', value: 'MIL' },
    { key: 'MIN', text: 'MIN', value: 'MIN' },
    { key: 'NOP', text: 'NOP', value: 'NOP' },
    { key: 'NYK', text: 'NYK', value: 'NYK' },
    { key: 'OKC', text: 'OKC', value: 'OKC' },
    { key: 'ORL', text: 'ORL', value: 'ORL' },
    { key: 'PHI', text: 'PHI', value: 'PHI' },
    { key: 'PHX', text: 'PHX', value: 'PHX' },
    { key: 'POR', text: 'POR', value: 'POR' },
    { key: 'SAC', text: 'SAC', value: 'SAC' },
    { key: 'SAS', text: 'SAS', value: 'SAS' },
    { key: 'TOR', text: 'TOR', value: 'TOR' },
    { key: 'UTA', text: 'UTA', value: 'UTA' },
    { key: 'WAS', text: 'WAS', value: 'WAS' }
  ],
  MLB: [
    { key: 'ARI', text: 'ARI', value: 'ARI' },
    { key: 'ATL', text: 'ATL', value: 'ATL' },
    { key: 'BAL', text: 'BAL', value: 'BAL' },
    { key: 'BOS', text: 'BOS', value: 'BOS' },
    { key: 'CHC', text: 'CHC', value: 'CHC' },
    { key: 'CIN', text: 'CIN', value: 'CIN' },
    { key: 'CLE', text: 'CLE', value: 'CLE' },
    { key: 'COL', text: 'COL', value: 'COL' },
    { key: 'CWS', text: 'CWS', value: 'CWS' },
    { key: 'DET', text: 'DET', value: 'DET' },
    { key: 'HOU', text: 'HOU', value: 'HOU' },
    { key: 'KC', text: 'KC', value: 'KC' },
    { key: 'LAA', text: 'LAA', value: 'LAA' },
    { key: 'LAD', text: 'LAD', value: 'LAD' },
    { key: 'MIA', text: 'MIA', value: 'MIA' },
    { key: 'MIL', text: 'MIL', value: 'MIL' },
    { key: 'MIN', text: 'MIN', value: 'MIN' },
    { key: 'NYM', text: 'NYM', value: 'NYM' },
    { key: 'NYY', text: 'NYY', value: 'NYY' },
    { key: 'OAK', text: 'OAK', value: 'OAK' },
    { key: 'PHI', text: 'PHI', value: 'PHI' },
    { key: 'PIT', text: 'PIT', value: 'PIT' },
    { key: 'SD', text: 'SD', value: 'SD' },
    { key: 'SEA', text: 'SEA', value: 'SEA' },
    { key: 'SF', text: 'SF', value: 'SF' },
    { key: 'STL', text: 'STL', value: 'STL' },
    { key: 'TB', text: 'TB', value: 'TB' },
    { key: 'TEX', text: 'TEX', value: 'TEX' },
    { key: 'TOR', text: 'TOR', value: 'TOR' },
    { key: 'WSH', text: 'WSH', value: 'WSH' }
  ]
}

export const BONUS_FILTER_OPTIONS = [
  { key: 'all', text: 'All', value: 'All' },
  { key: 'prize', text: 'Prizes', value: 'Prizes' },
  { key: 'payout', text: 'Payouts', value: 'Payouts' }
]